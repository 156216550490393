import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import { StaffRole } from 'types/staff'
import AgencyService from 'services/agenciesService'

export interface AssignUrbanXMemberToAgenciesRequest extends ApiRequestBody {
  staffMemberId: string
  staffRole: StaffRole | null
  agencyIds: string[]
}

const AssignStaffMemberToAgencies = async (
  assignStaffMemberRequest: AssignUrbanXMemberToAgenciesRequest
): Promise<void> => {
  const { getAuthToken } = assignStaffMemberRequest
  const token = await getAuthToken()
  if (!token || !assignStaffMemberRequest) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.post(
    'AssignStaffMemberToAgencies',
    assignStaffMemberRequest
  )
  return result.data || []
}

export default AssignStaffMemberToAgencies

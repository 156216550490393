import { useEffect, RefObject } from 'react'
import { Keys } from 'constants/keyCodes'

const useOutsideAlert = (
  ref: RefObject<HTMLElement>,
  onTriggerOutside: (trigger: string) => void
) => {
  useEffect(() => {
    /**
     * Trigger if clicked on outside of element
     */
    const handleClickOutside = (event: MouseEvent | KeyboardEvent) => {
      if (
        (ref.current && !ref.current.contains(event.target as Node)) ||
        (event as KeyboardEvent).keyCode === Keys.ESCAPE
      ) {
        onTriggerOutside('outside')
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleClickOutside) // Triggering when Escape key is pressed

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleClickOutside)
    }
  }, [ref])
}

export default useOutsideAlert

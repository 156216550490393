import { useContext, useState, useEffect, useMemo } from 'react'
import {
  AgxRow,
  AgxColumn,
  AgxBodyText,
  AgxButton,
  AgxToast,
  cleanFullAddress,
} from '@urbanx/agx-ui-components'
import isEqualWith from 'lodash/isEqualWith'
import { ContentLayout, PageLayout } from 'layout'
import { AgencyContext, UserContext } from 'contexts'
import { MenuTitles } from 'constants/menu'
import { Campaign } from 'types/campaigns'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  GetAllAgencies,
  GetCampaignsForAgency,
  GetAgentsInAgency,
  SetCampaignOwnership,
} from 'services'
import { Agency, Agent, Permission } from 'types/agency'
import { AgxToastState } from 'types/commonTypes'
import { FormPrompt } from 'components/FormPrompt'
import { isDirtyCompare } from 'utils/compares'
import AgentsSelector from './AgentsSelector'

const Permissions = () => {
  const user = useContext(UserContext)
  const queryClient = useQueryClient()

  const [selectedAgency, setSelectedAgency] = useContext(AgencyContext)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState<
    Campaign | undefined
  >()
  const { agencyId, campaignId } = useParams()
  const agenciesQueryKey = ['all-agencies']
  const campaignsQueryKey = [`campaigns-${agencyId}`, agencyId]
  const [, getAuthToken] = useAzureAuth()
  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })
  const { data: agencies } = useQuery<Agency[] | undefined>({
    queryKey: agenciesQueryKey,
    queryFn: () => GetAllAgencies(getAuthToken),
  })
  useEffect(() => {
    setSelectedAgency(agencies?.find((a) => a.id === agencyId))
  }, [agencyId, setSelectedAgency, agencies])

  const { data: campaigns, isLoading: isLoadingCampaigns } = useQuery<
    Campaign[] | undefined
  >({
    queryKey: campaignsQueryKey,
    queryFn: (queryKey) => {
      return GetCampaignsForAgency(queryKey, getAuthToken)
    },
  })

  const initialPermission = useMemo<Permission>(
    () => ({
      leadAgentId: selectedCampaign?.leadAgentId,
      secondAgentId: selectedCampaign?.secondAgentId,
      campaignId: campaignId,
      createdByUserId: selectedCampaign?.createdByUserId,
      assignedAgentIds: selectedCampaign?.assignedAgentIds ?? [],
    }),
    [campaignId, selectedCampaign]
  )
  const [permission, setPermission] = useState<Permission>()

  useEffect(() => {
    if (!isLoadingCampaigns) {
      setSelectedCampaign(campaigns?.find((c) => c.id === campaignId))
    }
  }, [campaigns, campaignId, isLoadingCampaigns])

  useEffect(() => {
    setPermission(initialPermission)
  }, [initialPermission])

  useEffect(() => {
    setIsFormDirty(!isEqualWith(initialPermission, permission, isDirtyCompare))
  }, [permission])

  const { data: agents } = useQuery<Agent[] | undefined>({
    queryKey: [`agentsInAgency-${agencyId}`, agencyId],
    queryFn: (queryKey) => GetAgentsInAgency(queryKey, getAuthToken),
  })

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [`agentsInAgency-${agencyId}`, agencyId],
    })
  }, [])

  const { mutate: updateCampaignItems, data: updateResponse } =
    useMutation(SetCampaignOwnership)

  useEffect(() => {
    if (updateResponse === 204) {
      setIsFormDirty(false)
      updateToastState({
        color: 'success',
        message: 'Role saved successfully',
        open: true,
      })
      queryClient.invalidateQueries({
        queryKey: [`campaigns-${selectedAgency?.id}`, selectedAgency?.id],
      })
    }
  }, [updateResponse])

  const saveChanges = () => {
    updateCampaignItems({
      campaignId: selectedCampaign?.id || '',
      leadAgentId: permission?.leadAgentId ?? '',
      secondAgentId: permission?.secondAgentId ?? '',
      assignedAgents: permission?.assignedAgentIds ?? [],
      getAuthToken,
    })
  }

  const activeAgents = useMemo(() => {
    return agents?.filter((a) => !a.softDeleted) || []
  }, [agents])

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle={`Campaigns / ${cleanFullAddress(
        selectedCampaign?.address
      )}`}
      isBlankContract={true}
      selectedCampaign={selectedCampaign}
    >
      <FormPrompt hasUnsavedChanges={isFormDirty} />
      <AgxToast selector="#agxToast" toastState={toastState} />
      <ContentLayout
        hasSideMenu={true}
        activeMenu={MenuTitles.PERMISSIONS}
        isBlankContract={true}
      >
        <AgxColumn veryLargeGap>
          <AgxRow spaceBetween extraClasses={'borderBottomContainer'}>
            <AgxRow largeGap>
              <AgxBodyText large>Edit Role</AgxBodyText>
            </AgxRow>
            <AgxRow largeGap>
              <AgxButton
                text="Save Changes"
                medium
                primary
                onClick={() => saveChanges()}
              />
            </AgxRow>
          </AgxRow>
          <div className="panelContainer">
            {!isLoadingCampaigns &&
              selectedCampaign &&
              activeAgents &&
              permission?.createdByUserId && (
                <AgentsSelector
                  agents={activeAgents ?? []}
                  permission={permission}
                  setPermission={setPermission}
                />
              )}
          </div>
        </AgxColumn>
      </ContentLayout>
    </PageLayout>
  )
}

export default Permissions

import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import { TeamMember } from 'types/agency'
import AgencyService from 'services/agenciesService'

interface CreateAgentRequest extends TeamMember, ApiRequestBody {}

const CreateAgent = async (
  requestBody: CreateAgentRequest
): Promise<number | undefined> => {
  const { getAuthToken, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.post('CreateAgent', {
    ...requestProperties,
  })

  return result.status
}

export default CreateAgent

import React, { useMemo } from 'react'
import {
  AgxBodyText,
  AgxColumn,
  AgxHeader,
  formatCurrency,
  formatPercentage,
} from '@urbanx/agx-ui-components'
import { NetworkPartnershipMetricData } from 'types/networkStatistics'
import { ContractDateToUse } from 'types/rex'

interface TableRunRateProps {
  dateRange: string
  partnershipData: NetworkPartnershipMetricData
  selectedFilter: ContractDateToUse
}

const TableRunRate: React.FC<TableRunRateProps> = ({
  dateRange,
  partnershipData,
  selectedFilter,
}) => {
  const [filteredStatistics, formattedMetricsTitle] = useMemo(() => {
    if (selectedFilter === ContractDateToUse.Settled) {
      return [partnershipData?.settledMetrics ?? [], 'Settled']
    } else if (selectedFilter === ContractDateToUse.Written) {
      return [partnershipData?.writtenMetrics ?? [], 'Written']
    } else if (selectedFilter === ContractDateToUse.Unconditional) {
      return [partnershipData?.unconditionalMetrics ?? [], 'Unconditional']
    }
    return [[], 'Written']
  }, [selectedFilter, partnershipData])

  const tableData = useMemo(
    () =>
      filteredStatistics?.map((stat, idx) => ({
        title: stat?.partnershipLevel ?? '0',
        listings: stat?.listings ?? '0',
        contracts: stat?.contracts ?? '0',
        salesVolume: formatCurrency(stat?.salesVolume ?? 0, 0),
        avgSalePrice: formatCurrency(stat?.avgSalesPrice ?? 0, 0),
        gci: formatCurrency(stat?.gci ?? 0, 0),
        avgComm: formatPercentage(stat?.avgCommission?.toString() ?? '0', 2),
        serviceFee: formatCurrency(stat?.serviceFee ?? 0, 0),
        avgDom: stat?.avgDom ?? 0,
        percentNetwork: formatPercentage(
          stat?.percentage?.toString() ?? '0',
          idx === filteredStatistics?.length - 1 ? 0 : 2
        ),
      })) ?? [],
    [filteredStatistics]
  )

  return (
    <AgxColumn extraClasses="metrics-data">
      <AgxColumn>
        <AgxHeader size={3}>{formattedMetricsTitle}</AgxHeader>
        <AgxBodyText medium>{dateRange}</AgxBodyText>
      </AgxColumn>
      <div className="breakeven-table">
        <div className="breakeven-table-header">
          <AgxBodyText extraClasses="breakeven-header left">State</AgxBodyText>
          <AgxBodyText extraClasses="breakeven-header left">
            Listings
          </AgxBodyText>
          <AgxBodyText extraClasses="breakeven-header left">
            Contracts
          </AgxBodyText>
          <AgxBodyText extraClasses="breakeven-header">
            Sales Volume
          </AgxBodyText>
          <AgxBodyText extraClasses="breakeven-header">
            Av Sale Price
          </AgxBodyText>
          <AgxBodyText extraClasses="breakeven-header">GCI</AgxBodyText>
          <AgxBodyText extraClasses="breakeven-header">Av Comm</AgxBodyText>
          <AgxBodyText extraClasses="breakeven-header">Service Fee</AgxBodyText>
          <AgxBodyText extraClasses="breakeven-header">% Network</AgxBodyText>
        </div>
        {tableData.map((row, index) => {
          return (
            <div
              key={index}
              className={
                row.title === 'Total'
                  ? 'breakeven-table-row total'
                  : 'breakeven-table-row'
              }
            >
              <AgxBodyText medium extraClasses="breakeven-cell left strong">
                {row.title}
              </AgxBodyText>
              <AgxBodyText extraClasses="breakeven-cell left strong">
                {row.listings}
              </AgxBodyText>
              <AgxBodyText extraClasses="breakeven-cell left strong">
                {row.contracts}
              </AgxBodyText>
              <AgxBodyText extraClasses="breakeven-cell strong">
                {row.salesVolume}
              </AgxBodyText>
              <AgxBodyText extraClasses="breakeven-cell strong">
                {row.avgSalePrice}
              </AgxBodyText>
              <AgxBodyText extraClasses="breakeven-cell strong">
                {row.gci}
              </AgxBodyText>
              <AgxBodyText extraClasses="breakeven-cell strong">
                {row.avgComm}
              </AgxBodyText>
              <AgxBodyText extraClasses="breakeven-cell strong">
                {row.serviceFee}
              </AgxBodyText>
              <AgxBodyText extraClasses="breakeven-cell strong">
                {row.percentNetwork}
              </AgxBodyText>
            </div>
          )
        })}
      </div>
    </AgxColumn>
  )
}

export default TableRunRate

import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import RexService from '../../rexService'

interface SetListingPublishedToPortalRequest extends ApiRequestBody {
  ListingId: string
  PublishToPortal: boolean
}

const setListingPublishedToPortal = async (
  requestBody: SetListingPublishedToPortalRequest
): Promise<void> => {
  const { getAuthToken } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new RexService(token)

  if (!service) return

  await service.rexService.post('SetListingPublishedToPortal', requestBody)
}

export default setListingPublishedToPortal

import { AustralianState } from '@urbanx/agx-ui-components'
import { SideMenu } from 'types/commonTypes'
import { StaffRole } from 'types/staff'

export enum Pages {
  DASHBOARD = '/',
  TEAMS = 'teams',
  BRANDING = 'branding',
  AGENCY_DETAILS = 'agency-details',
  DOCUMENT_DEFAULTS = 'document-defaults',
  PARTNERSHIP = 'partnership',
  INTEGRATIONS = 'integrations',
  MARKETING = 'marketing',
  CAMPAIGNS = 'campaigns',
  STAFF = '/staff',
  STAFF_LICENSE = '/staff-license',
  AGREEMENT = 'agreement',
  BLANKCONTRACT = 'manage',
  FILES = 'files',
  PERMISSIONS = 'permissions',
  LISTINGS = 'listings',
}

export enum MenuTitles {
  HOME = 'Home',
  TEAM_MEMBERS = 'Team Members',
  BRANDING_SETTINGS = 'Branding Settings',
  LICENSEE_DETAILS = 'Licensee Details',
  AGENCY_DETAILS = 'Agency Details',
  DOCUMENT_DEFAULTS = 'Document Defaults',
  INTEGRATIONS = 'Integrations',
  MARKETING_PACKAGES = 'Marketing Packages',
  CAMPAIGNS = 'Campaigns',
  AGREEMENT = 'Agreement',
  BLANKCONTRACT = 'Blank Contract',
  FILES = 'Files',
  PERMISSIONS = 'Permissions',
  PARTNERSHIP = 'Partnership',
  LISTINGS = 'Listings',
}

export const Menu: SideMenu[] = [
  {
    title: MenuTitles.HOME,
    includeOnlyInState: [],
  },
  {
    title: MenuTitles.TEAM_MEMBERS,
    pageLink: Pages.TEAMS,
    includeOnlyInState: [],
  },
  {
    title: MenuTitles.BRANDING_SETTINGS,
    pageLink: Pages.BRANDING,
    includeOnlyInState: [],
  },
  {
    title: MenuTitles.AGENCY_DETAILS,
    pageLink: Pages.AGENCY_DETAILS,
    includeOnlyInState: [],
  },
  {
    title: MenuTitles.DOCUMENT_DEFAULTS,
    pageLink: Pages.DOCUMENT_DEFAULTS,
    includeOnlyInState: [AustralianState.QLD],
  },
  {
    title: MenuTitles.PARTNERSHIP,
    pageLink: Pages.PARTNERSHIP,
    includeOnlyInState: [],
    requiredRoles: [
      StaffRole.BusinessManager,
      StaffRole.SuperAdmin,
      StaffRole.Executive,
    ],
  },
  {
    title: MenuTitles.INTEGRATIONS,
    pageLink: Pages.INTEGRATIONS,
    includeOnlyInState: [],
  },
  {
    title: MenuTitles.MARKETING_PACKAGES,
    pageLink: Pages.MARKETING,
    includeOnlyInState: [],
  },
  {
    title: MenuTitles.CAMPAIGNS,
    pageLink: Pages.CAMPAIGNS,
    includeOnlyInState: [],
  },
  {
    title: MenuTitles.LISTINGS,
    pageLink: Pages.LISTINGS,
    includeOnlyInState: [],
  },
]

export const StaffMenu: SideMenu[] = [
  {
    title: MenuTitles.HOME,
    includeOnlyInState: [],
  },
  {
    title: MenuTitles.TEAM_MEMBERS,
    pageLink: Pages.STAFF,
    includeOnlyInState: [],
  },
  {
    title: MenuTitles.LICENSEE_DETAILS,
    pageLink: Pages.STAFF_LICENSE,
    includeOnlyInState: [],
  },
]

export const BlankContractMenu: SideMenu[] = [
  /* TODO: Enable once functionality is implemented
  {
    title: MenuTitles.AGREEMENT,
    pageLink: Pages.AGREEMENT,
  }, */
  {
    title: MenuTitles.BLANKCONTRACT,
    pageLink: Pages.BLANKCONTRACT,
    includeOnlyInState: [],
  },
  {
    title: MenuTitles.FILES,
    pageLink: Pages.FILES,
    includeOnlyInState: [],
  },
  {
    title: MenuTitles.PERMISSIONS,
    pageLink: Pages.PERMISSIONS,
    includeOnlyInState: [],
  },
]

import AgencyService from 'services/agenciesService'

const GetAgenciesAssignedToStaffMember = async (
  staffMemberId: string,
  staffRole: string | null,
  getAuthToken: () => Promise<string | void>
): Promise<string[]> => {
  const token = await getAuthToken()
  if (!token || !staffMemberId) return []

  const service = new AgencyService(token)

  const request: { staffMemberId: string; role?: string } = { staffMemberId }

  if (staffRole) {
    request.role = staffRole
  }

  if (!service) return []

  const result = await service.agencyService.get(
    'GetAgenciesAssignedToStaffMember',
    request
  )
  return result.data || []
}

export default GetAgenciesAssignedToStaffMember

import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import AgencyService from 'services/agenciesService'

interface AddMarketingPackageRequest extends ApiRequestBody {
  AgencyId: string
  Name: string
  DisplayName: string
  IncludeRealEstateListing: boolean
  IncludeDomainListing: boolean
  DisplayItemisedPricingOnPdf: boolean
  Items: string[]
  AddOns: string[]
  assignedAgentIds: string[]
  specialConditions?: string
}

const AddMarketingPackage = async (
  requestBody: AddMarketingPackageRequest
): Promise<number | undefined> => {
  const { getAuthToken, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.post('AddMarketingPackage', {
    ...requestProperties,
  })

  return result.status
}

export default AddMarketingPackage

import React from 'react'
import {
  AgxLabel,
  AgxDivider,
  UploadedDocument,
  UploadedDocumentInfo,
} from '@urbanx/agx-ui-components'
import DocumentRow from './DocumentRow'

interface Props {
  documents: UploadedDocumentInfo[]
  downloadDocument: (document: UploadedDocument) => void
  getDocumentTypeLabel: (documentType: string) => string
}
const DocumentSection = (props: Props) => {
  const { documents, downloadDocument, getDocumentTypeLabel } = props
  return (
    <>
      {documents.map((document, innerIndex) => (
        <React.Fragment key={document.documentId}>
            <React.Fragment>
              {innerIndex > 0 && <AgxDivider />}
              <AgxLabel medium>
                {document.title ?? getDocumentTypeLabel(document.documentType)}
              </AgxLabel>
            </React.Fragment>
          <DocumentRow
            document={document}
            downloadDocument={downloadDocument}
          />
        </React.Fragment>
      ))}
    </>
  )
}

export default DocumentSection

import { QueryKey } from '@tanstack/react-query'
import AgencyService from 'services/agenciesService'

const getAgencyRexId = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<number | undefined> => {
  const token = await getAuthToken()
  if (!token) return undefined

  const agencyId = queryKey[1] as string
  const service = new AgencyService(token)

  if (!service) return undefined

  const result = await service.agencyService.get('GetAgencyRexId', { agencyId })

  return result.data
}

export default getAgencyRexId

import { EntityTimePeriodStatistics } from '@urbanx/agx-ui-components/dist/cjs/types/components/portal/Reporting/types'

export interface AgentRexDetails {
  agentId: string
  rexUserEmailAddress?: string
}

export interface RexCode {
  code: string
}

export interface ActiveListing {
  listingId: string
  imageUri?: string
  address?: string
  agents: string[]
  pushedToPortal: boolean
}

export interface SetListingPublishedToPortalRequest {
  listingId: string
  publishToPortal: boolean
}

export enum ListingsTableColumn {
  Image = 'image',
  Address = 'address',
  Agents = 'agents',
  Published = 'published',
}

export enum ContractDateToUse {
  Written,
  Unconditional,
  Settled,
}

export interface StaffGetEntityNumbersResponse {
  title: string
  statistics: EntityTimePeriodStatistics
}

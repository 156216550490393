import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import { Staff } from 'types/staff'
import StaffService from 'services/staffService'

interface CreateStaffMemberRequest extends ApiRequestBody {
  staff: Staff
}

const CreateStaffMember = async (
  requestBody: CreateStaffMemberRequest
): Promise<string | undefined> => {
  const { getAuthToken } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new StaffService(token)

  if (!service) return

  const { staff } = requestBody

  const result = await service.staffService.post('CreateStaffMember', staff)

  return result.data
}

export default CreateStaffMember

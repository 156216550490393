import { Fragment, useContext, useEffect, useState } from 'react'
import { MenuTitles } from 'constants/menu'
import { AgencyContext, UserContext } from 'contexts'
import { ContentLayout, PageLayout } from 'layout'
import { useQuery } from '@tanstack/react-query'
import { GetAllStaff } from 'services'
import { Staff, StaffAction } from 'types/staff'
import { useLocation } from 'react-router-dom'
import { PlaceHolderImage } from 'components'
import {
  AgxButton,
  AgxCaption,
  AgxBodyText,
  AgxHeader,
  AgxColumn,
  Images,
} from '@urbanx/agx-ui-components'
import EditStaffMember from './EditStaffMember'
import { useAzureAuth } from '../../hooks/useAzureAuth'

const StaffMembers = () => {
  const user = useContext(UserContext)
  const [selectedAgency, _] = useContext(AgencyContext)
  const location = useLocation()
  const [pageAction, setPageAction] = useState(StaffAction.VIEW)
  const [showEditStaffMember, setShowEditStaffMember] = useState(false)
  const [selectedStaffMember, setSelectedStaffMember] = useState<Staff>(
    new Staff()
  )

  const [, getAuthToken] = useAzureAuth()

  const isStaff = true
  const { data: staffMembers, refetch: allStaffRefetch } = useQuery<
    Staff[] | undefined
  >({
    queryKey: ['allStaffMembers'],
    queryFn: () => GetAllStaff(getAuthToken),
  })

  const renderStaffMember = (staffMember: Staff) => {
    const onEditMember = (member: Staff) => {
      setPageAction(StaffAction.EDIT)
      setSelectedStaffMember(member)
      setShowEditStaffMember(true)
    }

    return (
      <Fragment key={staffMember.email}>
        <tr className="tableRowStyle">
          <td width={'76px'}>
            <div className="thStatusContainer__teams">
              <Images.More className="rotate_90__teams" />
              {staffMember.photo ? (
                <img
                  src={staffMember.photo.publicPath}
                  style={{ height: 60, width: 60, borderRadius: '50%' }}
                />
              ) : (
                <PlaceHolderImage size={60} title={staffMember.firstName} />
              )}
            </div>
          </td>
          <td>
            <AgxBodyText
              medium
            >{`${staffMember.firstName} ${staffMember.lastName}`}</AgxBodyText>
            <br />
            <AgxCaption>{staffMember.title}</AgxCaption>
          </td>
          <td className="memberNameStyle">
            <AgxBodyText small extraClasses={'greyColor__teams'}>
              {staffMember.email}
            </AgxBodyText>
            <br />
            <AgxBodyText small extraClasses={'greyColor__teams'}>
              {staffMember.phoneNumber}
            </AgxBodyText>
          </td>
          <td>
            <AgxBodyText small extraClasses={'greyColor__teams'}>
              {staffMember.role}
            </AgxBodyText>
          </td>
          <td align="right">
            <div className="actionContainerTD">
              <AgxButton
                text="Edit"
                medium
                hollow
                onClick={() => onEditMember(staffMember)}
              />
            </div>
          </td>
        </tr>
      </Fragment>
    )
  }

  useEffect(() => {
    if (!showEditStaffMember) allStaffRefetch()
  }, [showEditStaffMember])

  useEffect(() => {
    if (staffMembers && pageAction === StaffAction.SELF_EDIT) {
      const findCurrentUser = staffMembers.find((s) => s.email === user?.agent)
      setSelectedStaffMember(findCurrentUser ?? new Staff())
      setShowEditStaffMember(true)
    }
  }, [staffMembers, pageAction])

  useEffect(() => {
    if (location.state && location.state.action) {
      setPageAction(location.state.action)
    }
  }, [location.state])

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle="Staff"
      isStaff={isStaff}
    >
      <ContentLayout
        hasSideMenu={true}
        activeMenu={MenuTitles.TEAM_MEMBERS}
        isStaff={isStaff}
      >
        {showEditStaffMember ? (
          <EditStaffMember
            loggedInStaffId={user?.agent || ''}
            onBack={() => {
              setPageAction(StaffAction.VIEW)
              setShowEditStaffMember(false)
            }}
            staffMember={selectedStaffMember}
            pageAction={pageAction}
          />
        ) : (
          <AgxColumn extraLargeGap>
            <table width={'100%'} style={{ paddingBottom: 30 }}>
              <tbody>
                <tr className="tableRowStyle">
                  <th colSpan={2} align="left">
                    <AgxHeader size={5}>Staff members</AgxHeader>
                  </th>
                  <th align="left">
                    <AgxBodyText small>Details</AgxBodyText>
                  </th>
                  <th align="left">
                    <AgxBodyText small>Role</AgxBodyText>
                  </th>
                  <th align="left"></th>
                </tr>
                {staffMembers &&
                  staffMembers.map((staffMember) =>
                    renderStaffMember(staffMember)
                  )}
                <tr>
                  <td colSpan={6} className="tdAddUser__teams">
                    <AgxButton
                      text="Add member"
                      medium
                      hollow
                      rightIcon={<Images.PersonAdd />}
                      onClick={() => {
                        setPageAction(StaffAction.CREATE)
                        setSelectedStaffMember(new Staff())
                        setShowEditStaffMember(true)
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </AgxColumn>
        )}
      </ContentLayout>
    </PageLayout>
  )
}

export default StaffMembers

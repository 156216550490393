import { QueryKey } from '@tanstack/react-query'
import CampaignsService from 'services/campaignsService'

const StaffGetUploadedFileLink = async (
  {
    queryKey,
  }: {
    queryKey: QueryKey
  },
  getAuthToken: () => Promise<string | void>
): Promise<string | undefined> => {
  const token = await getAuthToken()
  if (!token) return

  const service = new CampaignsService(token)

  if (!service) return

  const filePath = queryKey[1] as string

  const result = await service.campaignsService.get(
    'StaffGetUploadedFileLink',
    {
      filePath,
    }
  )

  return result.data
}

export default StaffGetUploadedFileLink

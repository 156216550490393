import CampaignFlowService from 'services/campaignFlowService'
import { ApiRequestBody } from '../../../interfaces/ApiRequestBody'

interface UpdateCampaignFlowIdRequest extends ApiRequestBody {
  AgencyId?: string
  Code?: number | null
}

const UpdateCampaignFlowId = async (
  requestBody: UpdateCampaignFlowIdRequest
): Promise<void> => {
  const { getAuthToken } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new CampaignFlowService(token)

  if (!service) return

  await service.campaignFlowService.post('SetAgencyCode', requestBody)
}

export default UpdateCampaignFlowId

import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import StaffService from 'services/staffService'

interface DeleteStaffMemberRequest extends ApiRequestBody {
  id: string
}

const DeleteStaffMember = async (
  requestBody: DeleteStaffMemberRequest
): Promise<void> => {
  const { getAuthToken } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new StaffService(token)

  if (!service) return

  const { id } = requestBody

  const result = await service.staffService.post('DeleteStaffMember', { id })

  return result.data
}

export default DeleteStaffMember

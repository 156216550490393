import {
  AgxBodyText,
  AgxColumn,
  AgxHeader,
  formatCurrency,
  formatNumber,
  formatPercentage,
} from '@urbanx/agx-ui-components'
import React, { useMemo } from 'react'
import { ExecutiveStatistics } from 'types/executiveStatistics'

const formatDateRangeCurrentDay = (inputDate: Date) => {
  const now = new Date()
  const currentYear = now.getFullYear()
  const currentMonth = now.getMonth()
  const inputYear = inputDate.getFullYear()
  const inputMonth = inputDate.getMonth()

  const isCurrentMonth =
    inputYear === currentYear && inputMonth === currentMonth
  const lastDayOfMonth = new Date(inputYear, inputMonth + 1, 0).getDate()
  const endDay = isCurrentMonth ? now.getDate() : lastDayOfMonth

  const monthLong = inputDate.toLocaleString('default', { month: 'long' })

  const formattedDateRange = `${monthLong} 1-${endDay} ${inputYear}`

  return formattedDateRange
}

interface TableKeyMetricsProps {
  date: string
  keyMetricsData: ExecutiveStatistics
}

const TableKeyMetrics: React.FC<TableKeyMetricsProps> = ({
  date,
  keyMetricsData,
}) => {
  const formattedDateRange = useMemo(() => {
    const [monthName, year] = date.split(' ')
    const parsedDate = new Date(`${monthName} 1, ${year}`)

    return formatDateRangeCurrentDay(parsedDate)
  }, [date])

  const tableData = useMemo(() => {
    if (!keyMetricsData) return []

    return [
      {
        title: 'Sales Volume',
        settled: formatCurrency(keyMetricsData.settled?.salesVolume ?? '', 0),
        projectedSettled: formatCurrency(
          keyMetricsData.projectedSettled?.salesVolume ?? '',
          0
        ),
        budget: keyMetricsData.budget?.salesVolume
          ? formatCurrency(keyMetricsData.budget.salesVolume, 0)
          : formatCurrency(0, 0),
        variance: formatCurrency(keyMetricsData.variance?.salesVolume ?? '', 0),
        positiveVariance: keyMetricsData.variance?.salesVolume >= 0,
      },
      {
        title: 'Contracts',
        settled: formatNumber(
          keyMetricsData.settled?.contracts?.toString() ?? '0',
          0
        ),
        projectedSettled: formatNumber(
          keyMetricsData.projectedSettled?.contracts?.toString() ?? '0',
          0
        ),
        budget: formatNumber(
          keyMetricsData.budget?.contracts?.toString() ?? '0',
          0
        ),
        variance: formatNumber(
          keyMetricsData.variance?.contracts?.toString() ?? '0',
          0
        ),
        positiveVariance: keyMetricsData.variance?.contracts >= 0,
      },
      {
        title: 'AV Sale Price',
        settled: formatCurrency(keyMetricsData.settled?.avgSalesPrice ?? '', 0),
        projectedSettled: formatCurrency(
          keyMetricsData.projectedSettled?.avgSalesPrice ?? '',
          0
        ),
        budget: keyMetricsData.budget?.avgSalesPrice
          ? formatCurrency(keyMetricsData.budget.avgSalesPrice, 0)
          : formatCurrency(0, 0),
        variance: formatCurrency(
          keyMetricsData.variance?.avgSalesPrice ?? '',
          0
        ),
        positiveVariance: keyMetricsData.variance?.avgSalesPrice >= 0,
      },
      {
        title: 'AV Comm Rate',
        settled: formatPercentage(
          keyMetricsData.settled?.avgCommRate?.toString() ?? '',
          2
        ),
        projectedSettled: formatPercentage(
          keyMetricsData.projectedSettled?.avgCommRate?.toString() ?? '',
          2
        ),
        budget: formatPercentage(
          keyMetricsData.budget?.avgCommRate?.toString() ?? '',
          2
        ),
        variance: formatPercentage(
          keyMetricsData.variance?.avgCommRate?.toString() ?? '',
          2
        ),
        positiveVariance: keyMetricsData.variance?.avgCommRate >= 0,
      },
      {
        title: 'AV Service Fee %',
        settled: formatPercentage(
          keyMetricsData.settled?.avgServiceFeePercent?.toString() ?? '',
          2
        ),
        projectedSettled: formatPercentage(
          keyMetricsData.projectedSettled?.avgServiceFeePercent?.toString() ??
            '',
          2
        ),
        budget: formatPercentage(
          keyMetricsData.budget?.avgServiceFeePercent?.toString() ?? '',
          2
        ),
        variance: formatPercentage(
          keyMetricsData.variance?.avgServiceFeePercent?.toString() ?? '',
          2
        ),
        positiveVariance: keyMetricsData.variance?.avgServiceFeePercent >= 0,
      },
      {
        title: 'AV Service Fee $',
        settled: formatCurrency(
          keyMetricsData.settled?.avgServiceFeeDollars ?? '',
          0
        ),
        projectedSettled: formatCurrency(
          keyMetricsData.projectedSettled?.avgServiceFeeDollars ?? '',
          0
        ),
        budget: keyMetricsData.budget?.avgServiceFeeDollars
          ? formatCurrency(keyMetricsData.budget.avgServiceFeeDollars, 0)
          : formatCurrency(0, 0),
        variance: formatCurrency(
          keyMetricsData.variance?.avgServiceFeeDollars ?? '',
          0
        ),
        positiveVariance: keyMetricsData.variance?.avgServiceFeeDollars >= 0,
      },
      {
        title: 'Service Fee - Projected',
        settled: formatCurrency(keyMetricsData.settled?.serviceFee ?? '', 0),
        projectedSettled: formatCurrency(
          keyMetricsData.projectedSettled?.serviceFee ?? '',
          0
        ),
        budget: formatCurrency(keyMetricsData.budget?.serviceFee ?? '', 0),
        variance: formatCurrency(keyMetricsData.variance?.serviceFee ?? '', 0),
        positiveVariance: keyMetricsData.variance?.serviceFee >= 0,
      },
      {
        title: 'Service Fee - Breakeven',
        settled: formatCurrency(keyMetricsData.settled?.breakeven ?? '', 0),
        projectedSettled: formatCurrency(
          keyMetricsData.projectedSettled?.breakeven ?? '',
          0
        ),
        budget: formatCurrency(keyMetricsData.budget?.breakeven ?? '', 0),
        variance: formatCurrency(keyMetricsData.variance?.breakeven ?? '', 0),
        positiveVariance: keyMetricsData.variance?.breakeven >= 0,
      },
    ]
  }, [keyMetricsData])

  return (
    <AgxColumn extraClasses="metrics-data">
      <AgxColumn>
        <AgxHeader size={3}>Key Metrics</AgxHeader>
        <AgxBodyText medium>{formattedDateRange}</AgxBodyText>
      </AgxColumn>
      <div className="breakeven-table">
        <div className="breakeven-table-header">
          <AgxBodyText extraClasses="breakeven-blank">.</AgxBodyText>
          <AgxBodyText extraClasses="breakeven-header">Settled</AgxBodyText>
          <AgxBodyText extraClasses="breakeven-header">
            Projected Settled
          </AgxBodyText>
          <AgxBodyText extraClasses="breakeven-header">Budget</AgxBodyText>
          <AgxBodyText extraClasses="breakeven-header">
            Variance Expected
          </AgxBodyText>
        </div>
        {tableData.map((row, index) => {
          const varianceClass = row.positiveVariance ? 'positive' : 'negative'
          return (
            <div key={index} className="breakeven-table-row">
              <AgxBodyText medium extraClasses="breakeven-cell left strong">
                {row.title}
              </AgxBodyText>
              <AgxBodyText small extraClasses="breakeven-cell">
                {row.settled}
              </AgxBodyText>
              <AgxBodyText medium extraClasses="breakeven-cell strong">
                {row.projectedSettled}
              </AgxBodyText>
              <AgxBodyText small extraClasses="breakeven-cell">
                {row.budget}
              </AgxBodyText>
              <AgxBodyText
                extraClasses={`breakeven-cell strong ${varianceClass}`}
              >
                {row.variance}
              </AgxBodyText>
            </div>
          )
        })}
      </div>
    </AgxColumn>
  )
}

export default TableKeyMetrics

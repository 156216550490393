import CampaignsService from 'services/campaignsService'

export const getFileLink = async (
  authToken: string,
  filePath: string
): Promise<number | undefined> => {
  try {
    const service = new CampaignsService(authToken)

    if (!service) return

    const { data: result } = await service.campaignsService.get(
      'GetUploadedFileLink',
      { filePath }
    )

    return result
  } catch (err) {
    console.log('err', err)
  }
}

export const uploadFile = async (
  authToken: string,
  file: string,
  campaignId: string,
  uploadedFormType: DocumentType,
  onUploadProgressChanged = null,
  abortSignal = null
): Promise<any> => {
  try {
    const service = new CampaignsService(authToken)

    if (!service) return

    const { data: result } = await service.campaignsService.post(
      'AddOrUpdateDocument',
      file,
      {
        CampaignId: campaignId,
        FileType: uploadedFormType,
        onUploadProgress: onUploadProgressChanged,
        signal: abortSignal,
      }
    )
    return result
  } catch (err) {
    console.log('err', err)
  }
}

export const editFile = async (
  authToken: string,
  file: string,
  campaignId: string,
  uploadedFormType: DocumentType,
  filePath: string,
  onUploadProgressChanged = null,
  abortSignal = null
): Promise<any> => {
  try {
    const service = new CampaignsService(authToken)

    if (!service) return
    const { data: result } = await service.campaignsService.post(
      'AddOrUpdateDocument',
      file,
      {
        CampaignId: campaignId,
        FileType: uploadedFormType,
        onUploadProgress: onUploadProgressChanged,
        filePath: filePath,
        signal: abortSignal,
      }
    )
    return result
  } catch (err) {
    console.log('err', err)
  }
}

export const deleteFile = async (
  authToken: string,
  filePath: string
): Promise<number | undefined> => {
  try {
    const service = new CampaignsService(authToken)

    if (!service) return

    const { status } = await service.campaignsService.post('RemoveFile', {
      filePath,
    })
    return status
  } catch (err) {
    console.log('err', err)
  }
}

/* eslint-disable react/prop-types */
import {
  AgxMultiOptionButton,
  formatPercentage,
} from '@urbanx/agx-ui-components'
import downloadButton from '../../assets/images/cloud-download.png'
import { useState } from 'react'
import {
  ExecutiveStatistics,
  RunRateInformation,
} from 'types/executiveStatistics'

interface ExportDropdownProps {
  tableMetrics: string
  keyMetricsData: ExecutiveStatistics
  runRateData: RunRateInformation
}

const ExportDropdown: React.FC<ExportDropdownProps> = ({
  tableMetrics,
  keyMetricsData,
  runRateData,
}) => {
  const [showExport, setShowExport] = useState(false)

  const handlePDFExport = () => {
    window.print()
    setShowExport(!showExport)
  }

  const handleCSVExport = () => {
    let csvString = ''
    let filename = 'export.csv'
    const formatDateForFilename = (month: number, year: number): string => {
      const monthName = getMonthName(month)
      return `${monthName}_${year}`
    }

    if (tableMetrics == 'keyMetrics') {
      const formattedDate = formatDateForFilename(
        keyMetricsData.month,
        keyMetricsData.year
      )
      filename = `Key_metrics_${formattedDate}.csv`
      csvString = keyMetricsToCsv(keyMetricsData)
    } else {
      const formattedDate = formatDateForFilename(
        runRateData.month,
        runRateData.year
      )
      filename = `Run_Rate_${formattedDate}.csv`
      csvString = runRateToCsv(runRateData)
    }

    downloadCsv(csvString, filename)
    setShowExport(!showExport)
  }

  const keyMetricsToCsv = (keyMetricsData: ExecutiveStatistics): string => {
    const headers = [
      'Metric',
      'Settled',
      'ProjectedSettled',
      'Budget',
      'Variance',
    ]

    const tableData = [
      {
        title: 'Sales Volume',
        settled: keyMetricsData.settled?.salesVolume ?? '',
        projectedSettled: keyMetricsData.projectedSettled?.salesVolume ?? '',
        budget: keyMetricsData.budget?.salesVolume ?? '',
        variance: keyMetricsData.variance?.salesVolume ?? '',
      },
      {
        title: 'Contracts',
        settled: keyMetricsData.settled?.contracts ?? '0',
        projectedSettled: keyMetricsData.projectedSettled?.contracts ?? '0',
        budget: keyMetricsData.budget?.contracts ?? '0',
        variance: keyMetricsData.variance?.contracts ?? '0',
      },
      {
        title: 'AV Sale Price',
        settled: keyMetricsData.settled?.avgSalesPrice ?? '',
        projectedSettled: keyMetricsData.projectedSettled?.avgSalesPrice ?? '',
        budget: keyMetricsData.budget?.avgSalesPrice ?? '',
        variance: keyMetricsData.variance?.avgSalesPrice ?? '',
      },
      {
        title: 'AV Comm Rate',
        settled: formatPercentage(
          keyMetricsData.settled?.avgCommRate?.toString() ?? '',
          0
        ),
        projectedSettled: formatPercentage(
          keyMetricsData.projectedSettled?.avgCommRate?.toString() ?? '',
          0
        ),
        budget: formatPercentage(
          keyMetricsData.budget?.avgCommRate?.toString() ?? '',
          0
        ),
        variance: formatPercentage(
          keyMetricsData.variance?.avgCommRate?.toString() ?? '',
          0
        ),
      },
      {
        title: 'AV Service Fee %',
        settled: formatPercentage(
          keyMetricsData.settled?.avgServiceFeePercent?.toString() ?? '',
          0
        ),
        projectedSettled: formatPercentage(
          keyMetricsData.projectedSettled?.avgServiceFeePercent?.toString() ??
            '',
          0
        ),
        budget: formatPercentage(
          keyMetricsData.budget?.avgServiceFeePercent?.toString() ?? '',
          0
        ),
        variance: formatPercentage(
          keyMetricsData.variance?.avgServiceFeePercent?.toString() ?? '',
          0
        ),
      },
      {
        title: 'AV Service Fee $',
        settled: keyMetricsData.settled?.avgServiceFeeDollars ?? '',
        projectedSettled:
          keyMetricsData.projectedSettled?.avgServiceFeeDollars ?? '',
        budget: keyMetricsData.budget?.avgServiceFeeDollars ?? '',
        variance: keyMetricsData.variance?.avgServiceFeeDollars ?? '',
      },
    ]

    const csvRows = [
      headers.join(','),
      ...tableData.map((row) => {
        return [
          row.title,
          row.settled,
          row.projectedSettled,
          row.budget,
          row.variance,
        ].join(',')
      }),
    ].join('\n')

    return csvRows
  }

  const runRateToCsv = (runRateData: RunRateInformation): string => {
    const headers = ['Metric', 'MTD', 'Run Rate', 'Historical', 'Change']

    const tableData = [
      {
        title: 'Listings',
        mtd: runRateData?.mtdListings ?? '',
        runRate: runRateData?.runRateListings ?? '',
        historical: runRateData?.historicalListings ?? '',
        change: formatPercentage(
          runRateData?.changeListings?.toString() ?? '',
          0
        ),
      },
      {
        title: 'Contracts',
        mtd: runRateData?.mtdContracts ?? '',
        runRate: runRateData?.runRateContracts ?? '',
        historical: runRateData?.historicalContracts ?? '',
        change: formatPercentage(
          runRateData?.changeContracts?.toString() ?? '',
          0
        ),
      },
      {
        title: 'Sales',
        mtd: runRateData?.mtdSales ?? '',
        runRate: runRateData?.runRateSales ?? '',
        historical: runRateData?.historicalSales ?? '',
        change: formatPercentage(runRateData?.changeSales?.toString() ?? '', 0),
      },
      {
        title: 'Average Sale Price',
        mtd: runRateData?.avgSalesPrice ?? '',
        runRate: '',
        historical: '',
        change: '',
      },
      {
        title: 'Conversion Rate',
        mtd: formatPercentage(runRateData?.conversionRate?.toString() ?? '', 0),
        runRate: '',
        historical: '',
        change: '',
      },
    ]

    const csvRows = [
      headers.join(','),
      ...tableData.map((row) => {
        return [
          row.title,
          row.mtd,
          row.runRate,
          row.historical,
          row.change,
        ].join(',')
      }),
    ].join('\n')

    return csvRows
  }

  const downloadCsv = (csvString: string, filename: string) => {
    const blob = new Blob([csvString], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('hidden', '')
    a.setAttribute('href', url)
    a.setAttribute('download', filename)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const getMonthName = (monthNumber: number): string => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return monthNames[monthNumber - 1]
  }

  return (
    <div>
      <AgxMultiOptionButton
        primary
        medium
        options={[
          {
            text: 'PDF file',
            onClick: handlePDFExport,
          },
          {
            text: 'CSV file',
            onClick: handleCSVExport,
          },
        ]}
        rightIcon={
          <img src={downloadButton} style={{ width: '18px', height: '18px' }} />
        }
        text="Export"
      />
    </div>
  )
}

export default ExportDropdown

import { UrbanXDetails } from 'types/agency'
import AgencyService from 'services/agenciesService'

const GetUrbanXDetails = async (
  getAuthToken: () => Promise<string | void>
): Promise<UrbanXDetails | undefined> => {
  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.get('GetUrbanXDetails')

  return result.data
}

export default GetUrbanXDetails

import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import RexService from 'services/rexService'

interface UpdateAgentRexDetailsRequest extends ApiRequestBody {
  AgentId: string
  RexUserEmailAddress?: string
}

const errorMessage = 'An error occurred updated agent rex details'

const UpdateAgentRexDetails = async (
  requestBody: UpdateAgentRexDetailsRequest
): Promise<string | undefined> => {
  const { getAuthToken } = requestBody

  const token = await getAuthToken()
  if (!token) return errorMessage

  const service = new RexService(token)

  if (!service) return errorMessage

  const { data } =
    (await service.rexService.put('UpdateAgentRexDetails', requestBody)) ?? {}

  return data
}

export default UpdateAgentRexDetails

import { useContext, Fragment, useState, useEffect, useMemo } from 'react'
import { MenuTitles } from 'constants/menu'
import { ContentLayout, PageLayout } from 'layout'
import { UserContext, AgencyContext } from '../../contexts'
import { Agency, Team, TeamMember, AgentRole } from 'types/agency'
import { AgxToastState } from 'types/commonTypes'
import { PlaceHolderImage } from '../../components'
import { useParams } from 'react-router-dom'
import {
  AgxButton,
  AgxCaption,
  AgxBodyText,
  AgxHeader,
  AgxLabel,
  AgxRow,
  AgxColumn,
  Images,
  getReadableKeyFromEnumStringValue,
  AgxToast,
} from '@urbanx/agx-ui-components'
import { EditTeamMember } from './components'
import ManageTeams from './components/manage-teams/ManageTeams'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getAgencyMembers,
  GetAllAgencies,
  getTeamsForAgency,
  SetTeamsForAgency,
  SetUrbanXTeamForAgency,
  GetUrbanXTeamForAgency,
} from 'services'
import './team.css'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { AgencyUrbanXTeam, AgencyUrbanXTeamRequest } from 'types/staff'
import UrbanXTeam from './components/manage-staff/UrbanXTeam'
import ManageUrbanXTeam from './components/manage-staff/ManageUrbanXTeam'

export enum TeamPageAction {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

const TeamPage = () => {
  const { agencyId } = useParams()
  const user = useContext(UserContext)
  const [selectedAgency, setSelectedAgency] = useContext(AgencyContext)
  const [showManageTeams, setShowManageTeams] = useState(false)
  const [showManageStaff, setShowManageStaff] = useState(false)
  const [pageAction, setPageAction] = useState(TeamPageAction.CREATE)
  const [selectedTeamMember, setSelectedTeamMember] = useState(new TeamMember())
  const queryClient = useQueryClient()

  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })

  const [, getAuthToken] = useAzureAuth()

  const { data: agencies } = useQuery<Agency[] | undefined>({
    queryKey: ['all-agencies'],
    queryFn: () => GetAllAgencies(getAuthToken),
    enabled: !selectedAgency,
  })

  const { data: teams } = useQuery<Team[] | undefined>({
    queryKey: [`team-${agencyId}`, agencyId],
    queryFn: (queryKey) => getTeamsForAgency(queryKey, getAuthToken),
  })

  const { data: agents, refetch: refetchAgencyMembers } = useQuery<
    TeamMember[] | undefined
  >({
    queryKey: [`agents-${agencyId}`, agencyId],
    queryFn: (queryKey) => getAgencyMembers(queryKey, getAuthToken),
  })

  const { data: urbanXTeam, refetch: refetchUrbanXTeam } = useQuery<
    AgencyUrbanXTeam | undefined
  >({
    queryKey: [`urbanXTeam-${agencyId}`, agencyId],
    queryFn: (queryKey) => GetUrbanXTeamForAgency(queryKey, getAuthToken),
    refetchOnMount: true,
  })

  const { mutate: setTeamsForAgency } = useMutation(SetTeamsForAgency, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`team-${agencyId}`, agencyId],
      })
      queryClient.invalidateQueries({
        queryKey: [`agents-${agencyId}`, agencyId],
      })
      updateToastState({
        color: 'success',
        message: 'Changes Saved',
        open: true,
      })
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error Updating Teams',
        open: true,
      })
    },
  })

  const { mutate: setUrbanXTeamForAgency } = useMutation(
    SetUrbanXTeamForAgency,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`urbanXTeam-${agencyId}`, agencyId],
        }),
          updateToastState({
            color: 'success',
            message: 'Changes Saved',
            open: true,
          })
      },
      onError: () => {
        updateToastState({
          color: 'error',
          message: 'Error Updating UrbanX Team',
          open: true,
        })
      },
    }
  )

  const onEditTeamMemberPopupClosed = () => {
    setShowEditTeamMember(false)
  }

  const [showEditTeamMember, setShowEditTeamMember] = useState(false)

  const manageTeamsClickHandler = () => {
    setShowManageTeams(true)
  }

  const addNewTeams = (teams: Team[]) => {
    setTeamsForAgency({
      agencyId: selectedAgency?.id || '',
      agencyTeams: teams,
      getAuthToken,
    })
  }

  const addTeamMember = () => {
    setSelectedTeamMember(new TeamMember())
    setPageAction(TeamPageAction.CREATE)
    setShowEditTeamMember(true)
  }

  const updateUrbanXTeam = (agencyId: string, urbanXTeam: AgencyUrbanXTeam) => {
    setUrbanXTeamForAgency({
      agencyId: agencyId || '',
      urbanXTeam: new AgencyUrbanXTeamRequest(
        urbanXTeam.businessManager?.email,
        urbanXTeam.listingsAdmin?.email,
        urbanXTeam.salesAdmin?.email,
        urbanXTeam.marketingExpert?.email
      ),
      getAuthToken,
    })
  }

  const renderAgent = (agent: TeamMember) => {
    const onEditMember = (member: TeamMember) => {
      refetchAgencyMembers().then(() => {
        setSelectedTeamMember(member)
        setPageAction(TeamPageAction.EDIT)
        setShowEditTeamMember(true)
      })
    }

    return (
      <Fragment key={agent.id}>
        <tr className="tableRowStyle">
          <td width={'76px'}>
            <div className="thStatusContainer__teams">
              {agent.photo ? (
                <img
                  src={agent.photo.publicPath}
                  style={{ height: 60, width: 60, borderRadius: '50%' }}
                />
              ) : (
                <PlaceHolderImage size={60} title={agent.firstName} />
              )}
            </div>
          </td>
          <td>
            <AgxBodyText
              medium
            >{`${agent.firstName} ${agent.lastName}`}</AgxBodyText>
            <br />
            <AgxCaption>{agent.title}</AgxCaption>
          </td>
          <td className="memberNameStyle">
            <AgxBodyText small extraClasses={'greyColor__teams'}>
              {agent.email}
            </AgxBodyText>
            <br />
            <AgxBodyText small extraClasses={'greyColor__teams'}>
              {agent.phone}
            </AgxBodyText>
          </td>
          <td>
            <AgxRow mediumGap extraClasses={'teamPermissions'} centered>
              <Images.LockOutline width={18} height={18} />
              <AgxBodyText small extraClasses={'greyColor__teams'}>
                {getReadableKeyFromEnumStringValue(AgentRole, agent.role) ?? ''}
              </AgxBodyText>
            </AgxRow>
          </td>
          <td>
            <AgxBodyText small extraClasses={'greyColor__teams'}>
              {teams
                ?.filter((t) => agent.teams.includes(t.id))
                .map((ft) => ft.name)
                .join(',')}
            </AgxBodyText>
          </td>
          <td align="right">
            <div className="actionContainerTD">
              <AgxButton
                text="Edit"
                medium
                hollow
                onClick={() => onEditMember(agent)}
              />
            </div>
          </td>
        </tr>
      </Fragment>
    )
  }

  useEffect(() => {
    refetchUrbanXTeam()
  }, [])

  useEffect(() => {
    if (!selectedAgency) {
      setSelectedAgency(agencies?.find((a) => a.id === agencyId))
    }
  }, [agencies])

  const activeAgents = useMemo(() => {
    return agents?.filter((a) => !a.softDeleted) || []
  }, [agents])

  if (!selectedAgency) return null

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle="Team"
    >
      <AgxToast selector="#agxToast" toastState={toastState} />

      <ContentLayout hasSideMenu={true} activeMenu={MenuTitles.TEAM_MEMBERS}>
        {showEditTeamMember ? (
          <EditTeamMember
            teams={teams || []}
            showPopUp={showEditTeamMember}
            teamMember={selectedTeamMember as TeamMember}
            onBack={() => onEditTeamMemberPopupClosed()}
            selectedAgencyId={selectedAgency.id}
            action={pageAction}
          />
        ) : (
          <></>
        )}

        {showManageTeams ? (
          <ManageTeams
            agencyName={selectedAgency.name}
            teams={teams || []}
            agents={activeAgents || []}
            showPopUp={showManageTeams}
            onBackClick={() => setShowManageTeams(false)}
            onSaveChanges={addNewTeams}
          />
        ) : (
          <></>
        )}

        {showManageStaff ? (
          <ManageUrbanXTeam
            agencyId={selectedAgency.id}
            urbanXTeam={urbanXTeam ?? new AgencyUrbanXTeam()}
            onBackClick={() => setShowManageStaff(false)}
            onSaveChanges={updateUrbanXTeam}
          />
        ) : (
          <></>
        )}

        {!showEditTeamMember && !showManageTeams && !showManageStaff ? (
          <AgxColumn extraLargeGap>
            <table width={'100%'} style={{ paddingBottom: 30 }}>
              <tbody>
                <tr className="tableRowStyle">
                  <th colSpan={2} align="left">
                    <AgxHeader size={5}>Team members</AgxHeader>
                  </th>
                  <th align="left">
                    <AgxBodyText small>Details</AgxBodyText>
                  </th>
                  <th align="left">
                    <AgxBodyText small>Role</AgxBodyText>
                  </th>
                  <th align="left">
                    <AgxBodyText small>Team</AgxBodyText>
                  </th>
                  <th align="left"></th>
                </tr>
                {activeAgents &&
                  activeAgents.map((agent) => renderAgent(agent))}
                <tr>
                  <td colSpan={6} className="tdAddUser__teams">
                    <AgxButton
                      text="Add user"
                      medium
                      hollow
                      rightIcon={<Images.PersonAdd />}
                      onClick={() => addTeamMember()}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="borderBottomContainer__teams">
              <AgxHeader size={5}>Teams</AgxHeader>
            </div>
            <AgxRow largeGap>
              {teams?.map((team) => (
                <AgxLabel key={team.name} extraClasses="teamTile__teams">
                  {team.name}
                </AgxLabel>
              ))}
            </AgxRow>
            <AgxRow>
              <AgxButton
                text="Manage Teams"
                medium
                primary
                rightIcon={<Images.People />}
                onClick={() => manageTeamsClickHandler()}
              />
            </AgxRow>
            <UrbanXTeam
              agencyId={selectedAgency.id}
              urbanXTeam={urbanXTeam ?? new AgencyUrbanXTeam()}
              onClick={() => setShowManageStaff(true)}
            />
          </AgxColumn>
        ) : (
          <></>
        )}
      </ContentLayout>
    </PageLayout>
  )
}

export default TeamPage

import { QueryKey } from '@tanstack/react-query'
import CampaignsService from 'services/campaignsService'
import { Campaign } from 'types/campaigns'

const getArchivedCampaigns = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<Campaign[] | undefined> => {
  const token = await getAuthToken()
  if (!token) return

  const service = new CampaignsService(token)
  if (!service) return

  const agencyId = queryKey[1] as string

  const result = await service.campaignsService.get(
    'GetArchivedCampaignsByAdmin',
    {
      AgencyId: agencyId,
    }
  )

  return result.data || {}
}

export default getArchivedCampaigns

import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import AgencyService from 'services/agenciesService'

interface DeleteLogoRequest extends ApiRequestBody {
  FilePath: string
  AgencyId: string
  getAuthToken: () => Promise<string | void>
}

const DeleteLogo = async (
  requestBody: DeleteLogoRequest
): Promise<number | undefined> => {
  const { getAuthToken, AgencyId } = requestBody

  if (!AgencyId) return

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const { FilePath } = requestBody

  const result = await service.agencyService.post('removepublicdocument', {
    FilePath,
  })

  return result.status
}

export default DeleteLogo

import { Navigate, useLocation } from 'react-router-dom'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { ReactElement } from 'react'
import { StaffRole } from 'types/staff'

interface AdditionalRouteProps {
  element: ReactElement
  requiredRoles: StaffRole[]
}

const ProtectedRoute = ({
  element: Component,
  requiredRoles,
}: AdditionalRouteProps) => {
  const [userAccount, , userHasRequiredRoles] = useAzureAuth()
  const location = useLocation()

  if (!userAccount) return <div></div>

  if (!userHasRequiredRoles(requiredRoles)) {
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return Component
}

export default ProtectedRoute

import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import AgencyService from 'services/agenciesService'

interface SoftDeleteAgentRequest extends ApiRequestBody {
  AgentId: string
}

const SoftDeleteAgent = async (requestBody: SoftDeleteAgentRequest) => {
  const { getAuthToken, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.put('SoftDeleteAgent', {
    ...requestProperties,
  })

  return result.status
}

export default SoftDeleteAgent

import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import { UploadedDocument } from 'types/agency'
import AgencyService from 'services/agenciesService'

interface UpdateBrandingRequest extends ApiRequestBody {
  AgencyName?: string
  BackgroundColor?: string
  BackgroundTextColor?: string
  AccentColor?: string
  ButtonTextColor?: string
  InboxBackgroundColor?: string
  InboxBackgroundColorOpacity?: string
  SidebarBackgroundColor?: string
  SidebarBackgroundColorOpacity?: string
  Logo?: UploadedDocument
  AgencyId?: string
}

const UpdateBranding = async (
  requestBody: UpdateBrandingRequest
): Promise<number | undefined> => {
  const { getAuthToken, AgencyId, ...requestProperties } = requestBody

  if (!AgencyId) return

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.put(
    `UpdateBranding?AgencyId=${AgencyId}`,
    { ...requestProperties }
  )

  return result.status
}

export default UpdateBranding

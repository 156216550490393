import { useContext } from 'react'
import { UserContext } from 'contexts'
import { PageLayout } from 'layout'
import { AgxButton, Images } from '@urbanx/agx-ui-components'
import { useNavigate } from 'react-router-dom'
import '../error.css'

const UnexpectedError = () => {
  const user = useContext(UserContext)
  const navigate = useNavigate()
  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName=""
      isError={true}
      transparent={true}
    >
      <div className="errorContainer__error">
        <div style={{ width: 500 }} className="errorContainer__error">
          <div className="circle__error">
            <Images.Warning />
          </div>
          <p className="smallTextStyle_error">500 Internal server error</p>
          <p className="largeTextStyle__error">Oops, something went wrong</p>
          <p className="smallTextStyle_error">
            The server encountered an internal error and was unable to complete
            your request. Please try again or contact us if the problem
            persists.
          </p>
        </div>
        <AgxButton
          text="Return home"
          large
          primary
          onClick={() => navigate('/')}
        />
      </div>
    </PageLayout>
  )
}

export default UnexpectedError

import { Budget } from 'types/executiveStatistics'
import AgencyService from 'services/agenciesService'

const setBudgetStatistics = async ({
  budgetData,
  getAuthToken,
}: {
  budgetData: Budget[]
  getAuthToken: () => Promise<string | void>
}): Promise<void> => {
  const token = await getAuthToken()
  if (!token) return undefined

  const service = new AgencyService(token)

  if (!service) return undefined

  await service.agencyService.put('SetExecutiveKeyBudget', budgetData)
}

export default setBudgetStatistics

import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import AgencyService from 'services/agenciesService'

interface UpdateMarketingPackageRequest extends ApiRequestBody {
  AgencyId: string
  MarketingPackageId: string
  Name: string
  DisplayName: string
  IncludeRealEstateListing: boolean
  IncludeDomainListing: boolean
  DisplayItemisedPricingOnPdf: boolean
  Items: string[]
  AddOns: string[]
  assignedAgentIds: string[]
  specialConditions?: string
}

const UpdateMarketingPackage = async (
  requestBody: UpdateMarketingPackageRequest
): Promise<void> => {
  const { getAuthToken, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  await service.agencyService.put('UpdateMarketingPackage', {
    ...requestProperties,
  })
}

export default UpdateMarketingPackage

import AgencyService from 'services/agenciesService'
import { ApiRequestBody } from 'interfaces/ApiRequestBody'

interface UpdateAgencyRexIdRequest extends ApiRequestBody {
  AgencyId: string
  RexId: number
}

const errorMessage = 'An error occurred updated rex agency Id'

const updateAgencyRexId = async (
  requestBody: UpdateAgencyRexIdRequest
): Promise<string | undefined> => {
  const { getAuthToken } = requestBody

  const token = await getAuthToken()
  if (!token) return errorMessage

  const service = new AgencyService(token)

  if (!service) return errorMessage

  const { data } =
    (await service.agencyService.post('UpdateAgencyRexId', requestBody)) ?? {}

  return data
}

export default updateAgencyRexId

import { QueryKey } from '@tanstack/react-query'
import RealestateService from '../../realestateService'
import { Realestate } from 'types/realestate'

const getRealestateCode = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<Realestate | undefined> => {
  const token = await getAuthToken()
  if (!token) return undefined

  const agencyId = queryKey[1] as string

  const service = new RealestateService(token)

  if (!service) return undefined

  const result = await service.realestateService.get('GetAgencyCode', {
    agencyId,
  })

  return result.data || undefined
}

export default getRealestateCode

import { useEffect, useCallback, useRef, useState } from 'react'
import { useBlocker, useBeforeUnload, BlockerFunction } from 'react-router-dom'
import Popup, { ActionButton } from './Popup/Popup'
import { AgxBodyText, Images } from '@urbanx/agx-ui-components'

interface Props {
  hasUnsavedChanges: boolean
  forceCheck?: boolean
  resetForceCheck?: (value: boolean) => void
  onBack?: () => void
}

export const FormPrompt = (props: Props) => {
  const { hasUnsavedChanges, forceCheck, resetForceCheck, onBack } = props
  const [showPopUp, setShowPopUp] = useState(false)
  const [discardChanges, setDiscardChanges] = useState(false)
  const [nextLocation, setNextLocation] = useState('')
  const actionButtons: ActionButton[] = [
    {
      title: 'Go Back',
      clickHandler: () => {
        setShowPopUp(false)
        resetForceCheck && resetForceCheck(false)
      },
    },
    {
      title: 'Discard changes',
      isPrimary: true,
      clickHandler: () => {
        if (forceCheck && onBack) {
          setShowPopUp(false)
          onBack()
          resetForceCheck && resetForceCheck(false)
        } else {
          setDiscardChanges(true)
          window.location.pathname = nextLocation
        }
      },
    },
  ]

  const onLocationChange = useCallback(
    ({ nextLocation }: any) => {
      setNextLocation(nextLocation.pathname)
      if (hasUnsavedChanges && nextLocation.hash === '') {
        setShowPopUp(true)
        return true
      }
      return false
    },
    [hasUnsavedChanges, discardChanges]
  )

  usePrompt(onLocationChange, hasUnsavedChanges, discardChanges)

  useBeforeUnload(
    useCallback(
      (event) => {
        if (hasUnsavedChanges && !showPopUp) {
          event.preventDefault()
          /**
           * Since Firefox 44, Chrome 51, Opera 38, and Safari 9.1,
           * a generic string not under the control of the webpage is shown instead of the returned string.
           *
           * For any older browser versions return the following prompt string
           */
          event.returnValue = 'Do you still wish to leave the page?'
        }
      },
      [hasUnsavedChanges, showPopUp]
    ),
    { capture: true }
  )

  useEffect(() => {
    if (forceCheck) setShowPopUp(true)
  }, [forceCheck])

  return (
    <Popup
      title={`You have unsaved changes`}
      isOpen={showPopUp}
      onClose={() => setShowPopUp(false)}
      actionButtons={actionButtons}
      size={{ widthInPX: 640 }}
      icon={<Images.AlertCircleOutline />}
    >
      <div>
        <AgxBodyText medium>Do you still wish to leave the page?</AgxBodyText>
      </div>
    </Popup>
  )
}

function usePrompt(
  onLocationChange: BlockerFunction,
  hasUnsavedChanges: boolean,
  discardChanges: boolean
) {
  const blocker = useBlocker(
    hasUnsavedChanges && !discardChanges ? onLocationChange : false
  )
  const prevState = useRef(blocker.state)

  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset()
    }
    prevState.current = blocker.state
  }, [blocker])
}

import { StaffApi } from '../helpers/http'

class CampaignsService {
  authToken: string
  campaignsService: StaffApi

  public constructor(authToken: string) {
    this.authToken = authToken
    this.campaignsService = new StaffApi('campaigns', authToken)
  }
}

export default CampaignsService

import AgencyService from 'services/agenciesService'
import { Agency } from 'types/agency'

const getAllAgencies = async (
  getAuthToken: () => Promise<string | void>
): Promise<Agency[] | undefined> => {
  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.get('GetAllAgencies')

  return result.data || []
}

export default getAllAgencies

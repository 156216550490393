import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import AgencyService from 'services/agenciesService'

interface CreateAgencyRequest extends ApiRequestBody {
  name: string
}

const CreateAgency = async (
  requestBody: CreateAgencyRequest
): Promise<number | void> => {
  const { name, getAuthToken } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.post('CreateAgency', {
    Name: name,
  })

  return result.data
}

export default CreateAgency

import { QueryKey } from '@tanstack/react-query'
import DomainService from 'services/domainService'
import { Domain } from 'types/domain'

const getDomainCode = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<Domain | undefined> => {
  const token = await getAuthToken()
  if (!token) return undefined

  const agencyId = queryKey[1] as string
  const service = new DomainService(token)

  if (!service) return undefined

  const result = await service.domainService.get('GetAgencyCode', { agencyId })

  return result.data
}

export default getDomainCode

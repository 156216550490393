import { StaffApi } from 'helpers/http'

class RexService {
  authToken: string
  rexService: StaffApi

  public constructor(authToken: string) {
    this.authToken = authToken
    this.rexService = new StaffApi('rex', authToken)
  }
}

export default RexService

import { QueryKey } from '@tanstack/react-query'
import { CampaignFlow } from '../../../types/campaignFlow'
import CampaignFlowService from 'services/campaignFlowService'

const getCampaignFlowId = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<CampaignFlow | undefined> => {
  const token = await getAuthToken()
  if (!token) return undefined

  const agencyId = queryKey[1] as string
  const service = new CampaignFlowService(token)

  if (!service) return undefined

  const result = await service.campaignFlowService.get('GetAgencyCode', {
    agencyId,
  })

  return result.data
}

export default getCampaignFlowId

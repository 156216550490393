import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MenuTitles } from '../../constants/menu'
import { ContentLayout, PageLayout } from '../../layout'
import { UserContext, AgencyContext } from '../../contexts'
import {
  AgxTextInput,
  AgxInfoLabel,
  AgxButton,
  AgxSelect,
  AgxColumn,
  AgxRow,
  AgxLabel,
  AgxToast,
} from '@urbanx/agx-ui-components'
import { PlaceHolderImage } from '../../components'
import { Agency, Branding, UploadedDocument } from 'types/agency'
import AGXColorPicker from '../../components/color-picker/AGXColorPicker'
import { hexToRGB } from '../../utils/colors'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  getBranding,
  UpdateBranding,
  UploadPublicDocument,
  GetAllAgencies,
} from 'services'
import { AgxToastState } from 'types/commonTypes'

import './branding.css'
import FileUpload from '../../components/file-upload/FileUpload'
import { useAzureAuth } from '../../hooks/useAzureAuth'
import { FormPrompt } from '../../components/FormPrompt'

type BrandingKeys = keyof Branding

const options = [
  { value: '#FFFFFF', label: 'White' },
  { value: '#000000', label: 'Black' },
]

const BrandingPage = () => {
  const { agencyId } = useParams()
  const queryClient = useQueryClient()
  const [isFormDirty, setIsFormDirty] = useState(false)
  const user = useContext(UserContext)
  const [selectedAgency, setSelectedAgency] = useContext(AgencyContext)
  const [logo, setLogo] = useState<UploadedDocument | undefined>(undefined)
  const [agencyName, setAgencyName] = useState('')
  const [img, updateImg] = useState('')
  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })
  const [brandingForm, setBrandingForm] = useState(new Branding())
  const [, getAuthToken] = useAzureAuth()

  const { data: agencies } = useQuery<Agency[] | undefined>({
    queryKey: ['all-agencies'],
    queryFn: () => GetAllAgencies(getAuthToken),
  })

  const { data: branding } = useQuery<Branding | undefined>({
    queryKey: [`branding-${agencyId}`, agencyId],
    queryFn: (queryKey) => getBranding(queryKey, getAuthToken),
  })

  const { mutate: updateBranding } = useMutation(UpdateBranding, {
    onSuccess: () => {
      setIsFormDirty(false)
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [`branding-${agencyId}`, agencyId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['all-agencies'],
        }),
      ])

      updateToastState({
        color: 'success',
        message: 'Branding updated successfully',
        open: true,
      })
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error updating branding',
        open: true,
      })
    },
  })
  const { mutate: uploadPublicDocument } = useMutation(UploadPublicDocument, {
    onSuccess: (r) => {
      updateImg(r?.publicPath || '')
      setLogo(r)
    },
  })

  useEffect(() => {
    if (selectedAgency) {
      setAgencyName(selectedAgency.name)
      updateImg(selectedAgency.logoUrl)
    }
  }, [selectedAgency])

  useEffect(() => {
    setSelectedAgency(agencies?.find((a) => a.id === agencyId))
  }, [agencies])

  useEffect(() => {
    if (branding) {
      setBrandingForm(branding)
      setLogo(branding?.logo)
      setIsFormDirty(false)
    }
  }, [branding])

  useEffect(() => {
    logo?.publicPath !== branding?.logo?.publicPath
      ? setIsFormDirty(true)
      : setIsFormDirty(false)
  }, [logo])

  const onBrandingChangeHandler = (value: string, prop: BrandingKeys) => {
    if (brandingForm) {
      if (value && brandingForm[prop] !== value) {
        setIsFormDirty(true)
      }

      setBrandingForm({ ...brandingForm, [prop]: value })
    }
  }

  const onSaveChanges = () => {
    updateBranding({
      AgencyId: agencyId || '',
      AgencyName: agencyName,
      BackgroundColor: brandingForm?.backgroundColor,
      BackgroundTextColor: brandingForm?.backgroundTextColor,
      AccentColor: brandingForm?.accentColor,
      ButtonTextColor: brandingForm?.buttonTextColor,
      InboxBackgroundColor: brandingForm?.inboxBackgroundColor,
      InboxBackgroundColorOpacity: `${brandingForm?.inboxBackgroundColorOpacity}`,
      SidebarBackgroundColor: brandingForm?.sidebarBackgroundColor,
      SidebarBackgroundColorOpacity: `${brandingForm?.sidebarBackgroundColorOpacity}`,
      Logo: logo,
      getAuthToken,
    })
  }

  const uploadLogo = (file: File) => {
    updateImg(URL.createObjectURL(file))
    uploadPublicDocument({
      selectedFile: file,
      AgencyId: agencyId,
      getAuthToken,
    })
  }

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle="Branding"
    >
      <ContentLayout
        hasSideMenu={true}
        activeMenu={MenuTitles.BRANDING_SETTINGS}
      >
        <FormPrompt hasUnsavedChanges={isFormDirty} />
        <AgxToast selector="#agxToast" toastState={toastState} />
        <AgxColumn veryLargeGap>
          <AgxRow spaceBetween centered extraClasses="borderBottomContainer">
            <AgxLabel large>Agency Settings</AgxLabel>
            <AgxButton
              text="Save Changes"
              medium
              primary
              onClick={() => onSaveChanges()}
            />
          </AgxRow>
          <AgxRow>
            <AgxColumn largeGap fill>
              <AgxTextInput
                id="agencyName"
                label="Agency Name"
                defaultValue={agencyName}
                onInputValueChange={({ value }: { value: string }) => {
                  if (value && agencyName !== value) {
                    setIsFormDirty(true)
                  }
                  setAgencyName(value)
                }}
                parentControlValue={true}
                noOptionalLabel
                extraClasses={'agencyNameTextbox__branding'}
              />

              <AgxRow largeGap extraClasses="flexCentre">
                {!img && <PlaceHolderImage title={agencyName} size={60} />}
                {img && (
                  <img
                    style={{
                      maxWidth: '150px',
                      background: brandingForm?.backgroundColor,
                      padding: '20px',
                      borderRadius: '8px',
                    }}
                    src={img}
                  />
                )}
                <FileUpload
                  id="fileUpload"
                  title=""
                  wide
                  extensions={['svg']}
                  uploadFile={(file) => uploadLogo(file)}
                />
                <AgxButton
                  text="Remove"
                  medium
                  hollow
                  primary
                  onClick={() => {
                    updateImg('')
                    setLogo(undefined)
                  }}
                />
              </AgxRow>
            </AgxColumn>
            <AgxColumn fill></AgxColumn>
            <AgxColumn fill>
              <AgxLabel>Preview</AgxLabel>
              <div
                className="demoLayout__branding"
                style={{ background: brandingForm?.backgroundColor }}
              >
                <div
                  className="demoSubContainer__branding"
                  style={{
                    background: `rgba(${
                      hexToRGB(brandingForm?.inboxBackgroundColor || '')?.r
                    }, ${
                      hexToRGB(brandingForm?.inboxBackgroundColor || '')?.g
                    }, ${
                      hexToRGB(brandingForm?.inboxBackgroundColor || '')?.b
                    }, ${
                      brandingForm?.inboxBackgroundColorOpacity &&
                      brandingForm?.inboxBackgroundColorOpacity / 100
                    })`,
                  }}
                >
                  <AgxLabel>Inbox text</AgxLabel>
                </div>
                <div
                  className="demoSubContainer__branding"
                  style={{
                    background: `rgba(${
                      hexToRGB(brandingForm?.sidebarBackgroundColor || '')?.r
                    }, ${
                      hexToRGB(brandingForm?.sidebarBackgroundColor || '')?.g
                    }, ${
                      hexToRGB(brandingForm?.sidebarBackgroundColor || '')?.b
                    }, ${
                      brandingForm?.sidebarBackgroundColorOpacity &&
                      brandingForm?.sidebarBackgroundColorOpacity / 100
                    })`,
                  }}
                >
                  <span style={{ color: brandingForm?.backgroundTextColor }}>
                    Sidebar text
                  </span>
                </div>
                <div className="horizontalContainer noBorder">
                  <div
                    className="demoSubContainer__branding"
                    style={{
                      background: brandingForm?.accentColor,
                    }}
                  >
                    <span style={{ color: brandingForm?.buttonTextColor }}>
                      Button
                    </span>
                  </div>
                  <div className="demoSubContainer__branding">
                    <span style={{ color: '#FFFFFF' }}>Button</span>
                  </div>
                </div>
              </div>
            </AgxColumn>
          </AgxRow>

          <div className="borderBottomContainer">
            <AgxLabel>Brand Colors</AgxLabel>
          </div>

          <AgxRow veryLargeGap centered extraClasses="borderBottomContainer">
            <AGXColorPicker
              id="bgColor"
              label="Background"
              defaultColor={branding?.backgroundColor}
              onColorChange={(hexColor) =>
                onBrandingChangeHandler(hexColor.toString(), 'backgroundColor')
              }
            />
            <AGXColorPicker
              id="bgTextColor"
              label="BG Text"
              defaultColor={branding?.backgroundTextColor}
              onColorChange={(v) =>
                onBrandingChangeHandler(v.toString(), 'backgroundTextColor')
              }
            />
            <AgxInfoLabel
              id="bglabel"
              label="The background of the page and the text on it"
            />
          </AgxRow>

          <AgxRow veryLargeGap centered extraClasses="borderBottomContainer">
            <AGXColorPicker
              id="accentColor"
              label="Accent"
              defaultColor={branding?.accentColor}
              onColorChange={(v) =>
                onBrandingChangeHandler(v.toString(), 'accentColor')
              }
            />
            <AGXColorPicker
              id="buttonText"
              label="Button Text"
              defaultColor={branding?.buttonTextColor}
              onColorChange={(v) =>
                onBrandingChangeHandler(v.toString(), 'buttonTextColor')
              }
            />

            <AgxInfoLabel
              id="buttonslabel"
              label="Used for buttons and action items"
            />
          </AgxRow>

          <AgxRow veryLargeGap centered extraClasses="borderBottomContainer">
            <div className="agxSelectContainer__branding">
              <AgxSelect
                id="inboxBg"
                label="Inbox BG"
                options={options}
                defaultValue={{
                  label: brandingForm?.inboxBackgroundColor,
                  value: brandingForm?.inboxBackgroundColor,
                }}
                hideOptionalLabel
                onValueChanged={({ value }: { value: string }) => {
                  onBrandingChangeHandler(value, 'inboxBackgroundColor')
                }}
              />
            </div>

            <AgxTextInput
              id="inboxOpacity"
              label="Opacity"
              defaultValue={`${branding?.inboxBackgroundColorOpacity}`}
              onInputValueChange={({ value }: { value: string }) => {
                onBrandingChangeHandler(value, 'inboxBackgroundColorOpacity')
              }}
              parentControlValue={true}
              noOptionalLabel
              percent
              extraClasses="smallTextBox__branding"
            />

            <AgxInfoLabel
              id="inboxlabel"
              label="Transparent background behind the inbox pane"
            />
          </AgxRow>

          <AgxRow veryLargeGap centered extraClasses="borderBottomContainer">
            <div className="agxSelectContainer__branding">
              <AgxSelect
                id="sidebarBG"
                label="Sidebar BG"
                options={options}
                defaultValue={{
                  label: brandingForm?.sidebarBackgroundColor,
                  value: brandingForm?.sidebarBackgroundColor,
                }}
                hideOptionalLabel
                onValueChanged={({ value }: { value: string }) => {
                  onBrandingChangeHandler(value, 'sidebarBackgroundColor')
                }}
              />
            </div>
            <AgxTextInput
              id="sidebarOpacity"
              label="Opacity"
              defaultValue={`${branding?.sidebarBackgroundColorOpacity}`}
              onInputValueChange={({ value }: { value: string }) =>
                onBrandingChangeHandler(value, 'sidebarBackgroundColorOpacity')
              }
              parentControlValue={true}
              noOptionalLabel
              percent
              extraClasses="smallTextBox__branding"
            />

            <AgxInfoLabel
              id="inboxlabel"
              label="Transparent background used for the sidebar background"
            />
          </AgxRow>
        </AgxColumn>
      </ContentLayout>
    </PageLayout>
  )
}

export default BrandingPage

import { AustralianState } from '@urbanx/agx-ui-components'
import { MenuTitles, Pages } from 'constants/menu'
import { StaffRole } from './staff'

export interface SideMenu {
  title: MenuTitles
  includeOnlyInState: AustralianState[]
  pageLink?: Pages
  requiredRoles?: StaffRole[]
}

export interface AgxToastState {
  open: boolean
  color: 'success' | 'info' | 'error'
  message: string
}

export enum NotificationState {
  NotSent,
  Sent,
  Sending,
}

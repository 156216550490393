import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import { SetPartnershipDetails } from 'types/partnershipDetails'
import AgencyService from 'services/agenciesService'

interface StaffSetPartnershipDetailsForAgencyRequest
  extends ApiRequestBody,
    SetPartnershipDetails {}

const UpdatePartnershipDetails = async (
  requestBody: StaffSetPartnershipDetailsForAgencyRequest
): Promise<number | undefined> => {
  const { getAuthToken, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.post(
    'StaffSetPartnershipDetailsForAgency',
    {
      ...requestProperties,
    }
  )

  return result.status
}

export default UpdatePartnershipDetails

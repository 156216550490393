import {
  AustralianState,
  FormType,
  PageStatus,
} from '@urbanx/agx-ui-components'
import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import CampaignsService from 'services/campaignsService'

interface FormSubmissionRequest extends ApiRequestBody {
  CampaignId: string
  FormId?: string
  FormType: FormType
  State: AustralianState
  BlankContractIsLive: boolean
  FormData: object
  PageStatuses: PageStatus[]
}

const saveForm = async (
  requestBody: FormSubmissionRequest
): Promise<number | undefined> => {
  const { getAuthToken, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new CampaignsService(token)

  if (!service) return

  const result = await service.campaignsService.post('StaffSaveForm', {
    ...requestProperties,
  })

  return result.status
}

export default saveForm

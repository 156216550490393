import { FeatureToggles } from '@urbanx/agx-ui-components'
import CampaignsService from 'services/campaignsService'

const GetFeatureToggles = async (
  getAuthToken: () => Promise<string | void>
): Promise<FeatureToggles | undefined> => {
  const token = await getAuthToken()
  if (!token) return

  const service = new CampaignsService(token)
  if (!service) return

  const { data: featureToggles } = await service.campaignsService.get('GetFeatureToggles')

  return featureToggles || {}
}

export default GetFeatureToggles

import { CampaignStageAction } from 'types/campaigns'
import CampaignsService from 'services/campaignsService'

interface IPerformCampaignAction {
  getAuthToken: () => Promise<string | void>
  campaignId: string
  campaignStageAction: CampaignStageAction
}

const PerformCampaignAction = async ({
  getAuthToken,
  campaignId,
  campaignStageAction,
}: IPerformCampaignAction) => {
  const token = await getAuthToken()
  if (!token) return

  const service = new CampaignsService(token)
  if (!service) return

  const result = await service.campaignsService.post('PerformCampaignAction', {
    CampaignId: campaignId,
    CampaignAction: campaignStageAction,
  })

  return result.data
}

export default PerformCampaignAction

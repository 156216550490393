import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import AgencyService from 'services/agenciesService'

interface RemoveMarketingPackageRequest extends ApiRequestBody {
  AgencyId: string
  MarketingPackageId: string
}

const RemoveMarketingPackage = async (
  requestBody: RemoveMarketingPackageRequest
): Promise<number | undefined> => {
  const { getAuthToken, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.post('RemoveMarketingPackage', {
    ...requestProperties,
  })

  return result.status
}

export default RemoveMarketingPackage
